import moment from 'moment';
import React, {
   type CSSProperties,
   useCallback,
   useEffect,
   useState,
} from 'react';
import {
   Button,
   Col,
   Container,
   FormControl,
   Modal,
   Row,
   Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
   getPoiRequest,
   getPoiRequestAccountData,
   type PoiRequestItem,
   rejectPoiRequest,
   replyPoiRequest,
} from '../actions/poi';
import COLORS from '../constants/colors';

const i18nPrefix = 'PoiRequests';

const PoiRequest = () => {
   const { token } = useParams();
   const { t } = useTranslation();
   console.log('token: ', token);
   const [loading, setLoading] = useState(true);
   const [poiRequestData, setPoiRequestData] = useState<PoiRequestItem>(null);
   const [accountData, setAccountData] = useState(null);
   const [price, setPrice] = useState<number>(null);
   const [currency, setCurrency] = useState('');
   const [comment, setComment] = useState('');
   const [showFeedbackModal, setShowFeedbackModal] = useState(false);
   const [isReply, setIsReply] = useState(true);
   const [invalidToken, setInvalidToken] = useState(false);

   const fetchPoiRequest = useCallback((token: string) => {
      setLoading(true);
      getPoiRequest(
         token,
         (res) => {
            // console.log('getPoiRequest res: ', res);
            setPoiRequestData(res);
            setPrice(res.price || null);
            setCurrency(res.currency);
            setComment(res.marina_comment);
            setLoading(false);
         },
         (err) => {
            console.log('getPoiRequest - error: ', err);
            setLoading(false);
            setInvalidToken(true);
            // window.location.replace(t(`${i18nPrefix}.forwardUrl`));
         },
      );

      getPoiRequestAccountData(
         token,
         (res) => {
            setAccountData(res);
         },
         (err) => {
            console.log('getPoiRequestAccountData - err: ', err);
         },
      );
   }, []);

   useEffect(() => {
      if (token) {
         fetchPoiRequest(token);
      }
   }, [fetchPoiRequest, token]);

   const onClickReply = useCallback(() => {
      if (!price || !currency) {
         toast(t(`${i18nPrefix}.fillPriceAndCurrency`), {
            type: 'error',
            autoClose: 3000,
         });
      } else {
         replyPoiRequest({
            token,
            price,
            currency,
            comment,
            callback: () => {
               // fetchPoiRequest(token);
               setIsReply(true);
               setShowFeedbackModal(true);
            },
            errorCallback: (err) => {
               console.log('replyPoiRequest err: ', err);
               toast(t(`${i18nPrefix}.replyFailed`) + err?.message, {
                  type: 'error',
                  autoClose: 5000,
               });
            },
         });
      }
   }, [comment, currency, price, token, t]);

   const onClickReject = useCallback(() => {
      rejectPoiRequest({
         token,
         comment,
         callback: (res) => {
            setIsReply(false);
            setShowFeedbackModal(true);
            console.log('rejectPoiRequest success: ', res);
         },
         errorCallback: (err) => {
            console.log('rejectPoiRequest err: ', err);
         },
      });
   }, [comment, token]);

   if (invalidToken) {
      return (
         <Container style={{ maxWidth: 800, marginTop: 50, borderRadius: 5 }}>
            <Row>
               <Col>
                  <h4>{t(`${i18nPrefix}.invalidTokenMessage`, {})}</h4>
               </Col>
            </Row>
         </Container>
      );
   }
   // valid token form here
   return (
      <Container style={{ maxWidth: 800, marginTop: 50, borderRadius: 5 }}>
         <FeedbackModal
            accountInfo={accountData}
            isReply={isReply}
            showFeedbackModal={showFeedbackModal}
            onClose={() => {
               setShowFeedbackModal(false);
               window.location.replace(t(`${i18nPrefix}.forwardUrl`));
            }}
         />
         {loading ? (
            <div
               style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
               }}>
               <Spinner
                  style={{ width: 20, height: 20, marginLeft: 10 }}
                  animation='border'
                  variant='primary'
                  role='status'
               />
            </div>
         ) : (
            <>
               <Row>
                  <Col>
                     <h4>{t(`${i18nPrefix}.ReplyPoiRequest`)}</h4>
                  </Col>
               </Row>
               <Row style={{ marginTop: 20, marginBottom: 20 }}>
                  <Col>
                     <div style={styles.label}>{t(`${i18nPrefix}.date`)}</div>
                     <FormControl
                        id='reservation_dates'
                        style={styles.formValue}
                        type='text'
                        value={`${moment(poiRequestData.from_date).format(
                           'DD-MM-YYYY',
                        )} - ${moment(poiRequestData.to_date).format('DD-MM-YYYY')}`}
                        disabled
                     />
                     <div style={styles.divider} />

                     <Row>
                        <div style={styles.label}>
                           {t(`${i18nPrefix}.accountComment`)}
                        </div>
                     </Row>
                     <Row>
                        <div style={styles.accountCommentStyle}>
                           {poiRequestData.account_comment}
                        </div>
                     </Row>
                     <div style={styles.divider} />

                     <div style={styles.label}>
                        {t(`${i18nPrefix}.boatNameAndRegistrationNumber`)}
                     </div>
                     <FormControl
                        id='boat_name'
                        style={styles.formValue}
                        type='text'
                        value={`${poiRequestData.boat.name} / ${poiRequestData.boat.registration_number}`}
                        disabled
                     />
                     <div style={styles.divider} />

                     <div style={styles.label}>
                        {t(`${i18nPrefix}.dimensions`)}
                     </div>
                     <FormControl
                        style={styles.formValue}
                        type='text'
                        value={`${poiRequestData.boat.length} x ${poiRequestData.boat.beam} x ${poiRequestData.boat.draft}`}
                        disabled
                     />
                     <div style={styles.divider} />

                     {poiRequestData?.boat?.type ||
                     poiRequestData?.boat?.weight ? (
                        <>
                           <div style={styles.label}>
                              {t(`${i18nPrefix}.boatTypeAndWeight`)}
                           </div>
                           <FormControl
                              style={styles.formValue}
                              type='text'
                              value={`${poiRequestData.boat.type_name ?? '-'} / ${poiRequestData.boat.weight ?? '-'}`}
                              disabled
                           />
                           <div style={styles.divider} />
                        </>
                     ) : null}
                  </Col>
               </Row>

               <Row xs={1} md={2} lg={4}>
                  <Col xs={8} md={4} style={{ minWidth: 100 }}>
                     <FormControl
                        type='number'
                        placeholder={t(`${i18nPrefix}.price`)}
                        value={price}
                        onChange={(e) => {
                           setPrice(Number(e.target.value) || null);
                        }}
                     />
                  </Col>
                  <Col xs={4} md={4} style={{ minWidth: 100 }}>
                     <FormControl
                        type='text'
                        placeholder={t(`${i18nPrefix}.currency`)}
                        value={currency}
                        onChange={(e) => {
                           setCurrency(e.target.value);
                        }}
                     />
                  </Col>
                  <Col xs={12} md={4} lg={4}>
                     <div
                        style={{
                           margin: 5,
                           fontSize: 18,
                        }}>
                        {t(`${i18nPrefix}.totalForNights`, {
                           n: moment(poiRequestData.to_date).diff(
                              moment(poiRequestData.from_date),
                              'days',
                           ),
                        })}
                     </div>
                  </Col>
               </Row>
               <Row>
                  <Col>
                     <FormControl
                        as='textarea'
                        type='text'
                        rows={5}
                        placeholder={t(`${i18nPrefix}.marinaComment`)}
                        value={comment}
                        onChange={(e) => {
                           setComment(e.target.value);
                        }}
                     />
                  </Col>
               </Row>

               <Row md={2} className='d-flex flex-column flex-md-row'>
                  <Col className='mb-2 mb-md-0'>
                     <Button
                        style={{
                           width: '95%',
                           minWidth: 200,
                           margin: 10,
                           boxSizing: 'border-box',
                        }}
                        variant='outline-danger'
                        onClick={onClickReject}>
                        {t(`${i18nPrefix}.reject`)}
                     </Button>
                  </Col>
                  <Col>
                     <Button
                        style={{
                           width: '95%',
                           minWidth: 200,
                           margin: 10,
                           boxSizing: 'border-box',
                        }}
                        variant='primary'
                        onClick={onClickReply}>
                        {t(`${i18nPrefix}.reply`)}
                     </Button>
                  </Col>
               </Row>
            </>
         )}
      </Container>
   );
};

type FeedbackModalProps = {
   accountInfo: {
      firstname: string;
      lastname: string;
      email: string;
      mobilenumber: string;
   };
   showFeedbackModal: boolean;
   isReply: boolean;
   onClose: () => void;
};
const FeedbackModal = ({
   accountInfo,
   showFeedbackModal,
   isReply,
   onClose,
}: FeedbackModalProps) => {
   const { t } = useTranslation();

   return (
      <Modal show={showFeedbackModal} onHide={onClose}>
         <Modal.Header closeButton>
            <Modal.Title>
               {isReply
                  ? t(`${i18nPrefix}.replySuccess`)
                  : t(`${i18nPrefix}.rejectSuccess`)}
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div>
               {isReply
                  ? t(`${i18nPrefix}.replyModalDescription`)
                  : t(`${i18nPrefix}.rejectModalDescription`)}
            </div>
            {isReply ? (
               <div style={{ marginTop: 10 }}>
                  <div style={styles.modalInfoRow}>
                     <span style={styles.infoLabel}>
                        {t(`${i18nPrefix}.accountName`)}
                     </span>
                     <span
                        style={
                           styles.infoValue
                        }>{`${accountInfo?.firstname} ${accountInfo?.lastname}`}</span>
                  </div>
                  <div style={styles.modalInfoRow}>
                     <span style={styles.infoLabel}>Email:</span>
                     <span style={styles.infoValue}>{accountInfo?.email}</span>
                  </div>
                  <div style={styles.modalInfoRow}>
                     <span style={styles.infoLabel}>
                        {t(`${i18nPrefix}.accountMobile`)}
                     </span>
                     <span style={styles.infoValue}>
                        {accountInfo?.mobilenumber}
                     </span>
                  </div>
               </div>
            ) : null}
         </Modal.Body>
         <Modal.Footer>
            <Button variant='outline-primary' onClick={onClose}>
               {t('global.ok')}
            </Button>
         </Modal.Footer>
      </Modal>
   );
};

const styles: Record<any, CSSProperties> = {
   label: {
      color: COLORS.MAIN_GRAY,
      alignSelf: 'center',
      fontSize: 14,
      float: 'left',
   },
   formValue: {
      border: 'none',
      borderRadius: 0,
      paddingLeft: 0,
      backgroundColor: 'white',
      color: COLORS.MAIN_BLUE,
   },
   divider: {
      width: '100%',
      height: 1,
      backgroundColor: COLORS.GRAY_OUTLINE,
      marginBottom: 5,
   },

   accountCommentStyle: {
      marginTop: 10,
      marginBottom: 16,
      color: COLORS.MAIN_BLUE,
   },

   modalInfoRow: {
      display: 'flex',
      columnGap: 10,
   },
   infoLabel: {
      fontSize: 15,
      fontWeight: 'bold',
   },
   infoValue: {
      color: COLORS.MAIN_BLUE,
      fontSize: 15,
      fontWeight: 500,
   },
};

export default PoiRequest;
