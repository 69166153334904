import axios from 'axios';

export type PoiRequestItem = {
   id: number;
   poi_id: number;
   language: string;
   status: 'pending' | string;
   account_id: number;
   boat_id: number;
   boat: {
      id: number;
      name: string;
      registration_number?: string;
      length?: number;
      beam?: number;
      draft?: number;
      type?: any;
      type_name?: string;
      weight?: any;
   };
   from_date: string;
   to_date: string;
   account_comment?: string;
   accepted_on?: string;
   price?: number;
   currency?: string;
   marina_comment?: string;
   declined_on?: string;
   token: string;
   created_on?: string;
   last_changed_on?: string;
   poi?: {
      language?: string;
      currency?: string;
   };
};

export function getPoiRequest(
   token: string,
   callback: (res: PoiRequestItem) => void,
   errorCallback: (err: Error) => void,
) {
   axios
      .get(`poi_requests/get?token=${token}`)
      .then((response) => {
         if (response.status == 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorCallback(err);
      });
}

export function getPoiRequestAccountData(
   token: string,
   callback: (res: PoiRequestItem) => void,
   errorCallback: (err: Error) => void,
) {
   axios
      .get(`poi_requests/get_account_details?token=${token}`)
      .then((response) => {
         if (response.status == 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorCallback(err);
      });
}

export function replyPoiRequest({
   token,
   price,
   currency,
   comment,
   callback,
   errorCallback,
}: {
   token: string;
   price: number;
   currency: string;
   comment?: string;
   callback: (res: any) => void;
   errorCallback: (err: Error) => void;
}) {
   axios
      .post('poi_requests/reply', { token, price, currency, comment })
      .then((response) => {
         if (response.status == 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorCallback(err);
      });
}

export function rejectPoiRequest({
   token,
   comment,
   callback,
   errorCallback,
}: {
   token: string;
   comment?: string;
   callback: (res: any) => void;
   errorCallback: (err: Error) => void;
}) {
   axios
      .post('poi_requests/reject', { token, comment })
      .then((response) => {
         if (response.status == 200) {
            callback(response.data);
         }
      })
      .catch((err) => {
         errorCallback(err);
      });
}
